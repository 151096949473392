import * as React from 'react';
import { Box, Grid } from '@mui/material';
import imageIndex from '../ImageIndex';

const whiteLogos = [
  { src: imageIndex.UMGLogo, link: 'https://umg.edu.mx/portal/', name: '' },
  { src: imageIndex.SkarchLogo, link: 'https://aguaskarch.com', name: '' },
  { src: imageIndex.AnsysLogo, link: 'https://www.ansys.com', name: '' },
  { src: imageIndex.AutoTokioNissanLogo, link: 'https://www.nissanchihuahua.com', name: '' },
  { src: imageIndex.AXONLogo, link: 'https://www.axon-cable.com', name: '' },
  { src: imageIndex.Bid360Logo, link: 'https://bid360.mx', name: '' },
  { src: imageIndex.CraftAviaLogo, link: 'https://craftaviacenter.com', name: '' },
  { src: imageIndex.DaviLogo, link: 'https://laboratorioparaconstruccion.com', name: '' },
  { src: imageIndex.GrupoSSCLogo, link: 'https://www.grupossc.com', name: '' },
  { src: imageIndex.DewesoftLogo, link: 'https://dewesoft.com/es', name: '' },
  { src: imageIndex.ERAAviacionLogo, link: 'https://www.eraaviacion.com', name: '' },
  { src: imageIndex.HoneywellAerospaceTechnologiesLogo, link: 'https://aerospace.honeywell.com' },
  { src: imageIndex.NoMADALogo, link: 'https://nomada-industries.com', name: '' },
  { src: imageIndex.SharevoltsLogo, link: 'https://sharevolts.mx/en/', name: '' },
  { src: imageIndex.MarDSalLogo, link: 'https://www.instagram.com/mar.d.sal_/', name: '' },
  { src: imageIndex.RedColaLogo, link: 'https://www.redcola.mx', name: '' },
  { src: imageIndex.SidralAgaLogo, link: 'https://www.agacentro.com', name: '' },
  { src: imageIndex.SterenLogo, link: 'https://www.steren.com.mx', name: '' },
  { src: imageIndex.ThrustersUnlimitedLogo, link: 'https://thrustersunlimited.space', name: '' },
];

const logoStyle = {
  width: '150px',
  height: 'auto',
  margin: '0 64px',
  paddingBottom: '40px',
  opacity: 0.7,
  transition: 'transform 0.3s, opacity 0.3s',
};

const hoverStyle = {
  transform: 'scale(1.8)',
  opacity: 1,
};

export default function SponsorLogos() {
  const logos = whiteLogos;
  const repeatTimes = 1;

  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      {[...Array(repeatTimes)].map((_, repeatIndex) => (
        <Grid container key={repeatIndex} sx={{ justifyContent: 'center', alignItems: 'center', mt: 0.5, opacity: 0.6 }}>
          {logos.map((logo, index) => (
            <Grid item key={index}>
              <a href={logo.link} target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block' }}>
              <img
                src={logo.src}
                alt={`Logo ${index + 1}`}
                style={logoStyle}
                onMouseOver={(e) => {
                  e.currentTarget.style.transform = hoverStyle.transform;
                  e.currentTarget.style.opacity = hoverStyle.opacity;
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.transform = '';
                  e.currentTarget.style.opacity = logoStyle.opacity;
                }}
              />
              </a>
            </Grid>
          ))}
        </Grid>
      ))}
    </Box>
  );
}
