import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import homeIcon from '../assets/enmice-blanco.png';
import { useLocation } from 'react-router-dom';

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
      {'© '}
      {new Date().getFullYear()}
      {' - ENMICE - ENCUENTRO MEXICANO DE INGENIERIA EN COHETERIA EXPERIMENTAL. ENMICE es una marca de Servicios de Exploración Espacial S de RL de CV. Guadalajara, Jalisco. México.'}
      <Link
        href='https://drive.google.com/file/d/1LhCyxl6DT5ccZHdB3sZ9G17znLIetK4O/view'
        target="_blank"
        variant="body2"
        sx={{
          fontWeight: 'bold',
          display: 'inline-flex',
          alignItems: 'center',
          '& > svg': { transition: '0.2s' },
          '&:hover > svg': { transform: 'translateX(2px)' },
        }}
      >
        <span>Política de privacidad.</span>
        <ChevronRightRoundedIcon fontSize="small" sx={{ mt: '1px', ml: '2px' }} />
      </Link>      
    </Typography>
    
  );
}

export default function Footer() {
  const location = useLocation();
  const isSpecificPage2 = location.pathname === '/campus-chief';
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
            <IconButton component={Link} to="/" sx={{width: 100, height: 'auto', padding: 0 }}>
                <img src={homeIcon} alt="Home" style={{ width: '100%', height: 'auto' }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 4 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Copyright />
        </div>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: 'left', color: 'text.secondary' }}
        >
          <IconButton
            sx={{
              alignSelf: 'center',
              color: isSpecificPage2 ? 'rgb(239, 80, 33)' : 'inherit',
            }}
            href="https://www.facebook.com/enmicemx"
            aria-label="Facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            sx={{
              alignSelf: 'center',
              color: isSpecificPage2 ? 'rgb(239, 80, 33)' : 'inherit',
            }}
            href="https://www.instagram.com/enmicemx"
            aria-label="Instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            sx={{
              alignSelf: 'center',
              color: isSpecificPage2 ? 'rgb(239, 80, 33)' : 'inherit',
            }}
            href="https://mx.linkedin.com/company/enmicemx"
            aria-label="LinkedIn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </IconButton>
          <IconButton
            sx={{
              alignSelf: 'center',
              color: isSpecificPage2 ? 'rgb(239, 80, 33)' : 'inherit',
            }}
            href="https://www.youtube.com/@ENMICE"
            aria-label="YouTube"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YouTubeIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
