import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import RocketIcon from '@mui/icons-material/Rocket';
import imageIndex from '../ImageIndex';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useState, useEffect } from 'react';

const items = [
  {
    icon: <RocketIcon />,
    title: '<strong style="color: hsl(210, 100%, 65%);">Sede principal:</strong> EXPO Mundo Imperial en Acapulco.\n<strong style="color: hsl(210, 100%, 65%);">Sede lanzamientos:</strong> Playa de San Marcos.\n\n',
    description: '<strong style="color: hsl(210, 100%, 65%);">53</strong> equipos registrados\n<strong style="color: hsl(210, 100%, 65%);">20</strong> estados diferentes\n<strong style="color: hsl(210, 100%, 65%);">772</strong> integrantes de equipos\n<strong style="color: hsl(210, 100%, 65%);">-</strong> participantes\n<strong style="color: hsl(210, 100%, 65%);">15</strong> colaboradores y aliados\n<strong style="color: hsl(210, 100%, 65%);">18</strong> patrocinadores',
    imageDark: `url(${imageIndex.FlyerGuerrero})`,
    url: 'https://google.com',
  }
];

const items2 = [
  {
    icon: <RocketIcon />,
    title: '<strong style="color: hsl(210, 100%, 65%);">53</strong> equipos registrados\n<strong style="color: hsl(210, 100%, 65%);">20</strong> estados participantes\n<strong style="color: hsl(210, 100%, 65%);"># por definir</strong> participantes\n\n',
    description: '<strong style="color: hsl(210, 100%, 65%);">1)</strong> Baja California\n<strong style="color: hsl(210, 100%, 65%);">2)</strong> Jalisco\n<strong style="color: hsl(210, 100%, 65%);">3)</strong> Nuevo León\n<strong style="color: hsl(210, 100%, 65%);">4)</strong> Sonora\n<strong style="color: hsl(210, 100%, 65%);">5)</strong> Guanajuato\n<strong style="color: hsl(210, 100%, 65%);">6)</strong> Durango\n<strong style="color: hsl(210, 100%, 65%);">7)</strong> CDMX\n<strong style="color: hsl(210, 100%, 65%);">8)</strong> Puebla\n<strong style="color: hsl(210, 100%, 65%);">9)</strong> Hidalgo\n<strong style="color: hsl(210, 100%, 65%);">10)</strong> Estado de México\n<strong style="color: hsl(210, 100%, 65%);">11)</strong> Yucatán\n<strong style="color: hsl(210, 100%, 65%);">12)</strong> Guerrero\n<strong style="color: hsl(210, 100%, 65%);">13)</strong> Veracruz\n<strong style="color: hsl(210, 100%, 65%);">14)</strong> Tamaulipas\n<strong style="color: hsl(210, 100%, 65%);">15)</strong> Morelos\n<strong style="color: hsl(210, 100%, 65%);">16)</strong> Tlaxcala\n<strong style="color: hsl(210, 100%, 65%);">17)</strong> Chihuahua\n<strong style="color: hsl(210, 100%, 65%);">18)</strong> Michoacán',
    imageDark: `url(${imageIndex.ENMICE20232024Map})`,
    url: 'https://google.com',
  }
];

const items3 = [
  {
    icon: <RocketIcon />,
    title: '<strong style="color: hsl(210, 100%, 65%);">Sede principal:</strong> Universidad Autónoma de Chihuahua (UACH).\n<strong style="color: hsl(210, 100%, 65%);">Sede lanzamientos:</strong> Campos Menonitas en CD. Cuauhtémoc.\n\n',
    description: '<strong style="color: hsl(210, 100%, 65%);">53</strong> equipos registrados\n<strong style="color: hsl(210, 100%, 65%);">20</strong> estados diferentes\n<strong style="color: hsl(210, 100%, 65%);">772</strong> integrantes de equipos\n<strong style="color: hsl(210, 100%, 65%);">1,500</strong> participantes\n<strong style="color: hsl(210, 100%, 65%);">26</strong> colaboradores y aliados\n<strong style="color: hsl(210, 100%, 65%);">20</strong> patrocinadores',
    imageDark: `url(${imageIndex.FlyerChihuahua})`,
    url: 'https://google.com',
  }
];

const ImageCarousel = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const carouselImageStyle = {
    width: '100%', // Adjust width as needed
    height: '500px', // Adjust height as needed
    objectFit: 'cover', // This ensures the images cover the div without stretching
    position: 'relative',
  };

  const baseOverlayTextStyle = {
    position: 'absolute',
    top: '70%',
    backgroundColor: 'hsla(220, 25%, 10%, 0.9)',
    borderRadius: '12px',
    padding: '20px',
    fontSize: '40px',
    margin: 10
  };

  const mobileOverlayTextStyle = {
    fontSize: '10px', // Smaller font size for mobile
    padding: '20px', // Less padding for mobile
    top: '50%' // Adjust position for mobile
  };

  const overlayTextStyle = isMobile ? { ...baseOverlayTextStyle, ...mobileOverlayTextStyle } : baseOverlayTextStyle;

  const sliderTextStyle1 = {
    flexDirection: 'row',
    margin: 0,
    fontWeight: 600,
    lineHeight: 1.2,
    letterSpacing: '-0.5px',
    fontFamily: 'Inter, sans-serif',
    display: 'flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    fontSize: 'clamp(3rem, 10vw, 3.5rem)',
  };

  const sliderTextStyle2 = {
    margin: 0,
    fontWeight: 600,
    lineHeight: 1.2,
    letterSpacing: '-0.5px',
    fontFamily: 'Inter, sans-serif',
    color: 'hsl(210, 100%, 65%)',
    fontSize: 'inherit',
  };

  return (
    <Slider {...settings}>
      <div style={carouselImageStyle}>
        <img src={imageIndex.HomeCarousel1} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
        {!isMobile && (
        <div style={overlayTextStyle}>
          <h1 style={sliderTextStyle1}>La competencia líder</h1>
          <h1 style={sliderTextStyle2}>de cohetería experimental en México</h1>
        </div>
        )}
      </div>
      <div style={carouselImageStyle}>
        <img src={imageIndex.HomeCarousel2} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
        {!isMobile && (
        <div style={overlayTextStyle}>
        <h1 style={sliderTextStyle1}>La competencia líder</h1>
        <h1 style={sliderTextStyle2}>de cohetería experimental en México</h1>
        </div>
        )}
      </div>
      <div style={carouselImageStyle}>
        <img src={imageIndex.HomeCarousel7} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
        {!isMobile && (
        <div style={overlayTextStyle}>
        <h1 style={sliderTextStyle1}>La competencia líder</h1>
        <h1 style={sliderTextStyle2}>de cohetería experimental en México</h1>
        </div>
        )}
      </div>
    </Slider>
  );
};

export default function ENMICE20232024Hero() {
  const [selectedItemIndex] = React.useState(0);
  const selectedFeature = items[selectedItemIndex];
  const selectedFeature2 = items2[selectedItemIndex];

  return (
    <React.Fragment>
      <Box
        id="hero"
        sx={(theme) => ({
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
          ...theme.applyStyles('dark', {
            backgroundImage:
              'radial-gradient(ellipse 70% 110% at 50% -20%, hsl(210, 100%, 16%), transparent)',
          }),
        })}
      >
        <ImageCarousel />
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: { xs: 15, sm: 15 },
            pb: { xs: 10, sm: 10 },
          }}
        >
          <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <Box
            component="img"
            src={imageIndex.ENMICELogo}
            alt="Academia ENMICE"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              width: '40%', // Adjust as needed
              height: 'auto', // Adjust as needed
              maxWidth: '100%',
              marginBottom: '50px' // Ensure the image is responsive
            }}
          />
        </Stack>
          <Stack
            spacing={2}
            useFlexGap
            sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
          >
            <Typography
              variant="h1"
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                fontSize: 'clamp(3rem, 10vw, 3.5rem)',
              }}
            >
              ENMICE&nbsp;
              <Typography
                component="span"
                variant="h1"
                sx={(theme) => ({
                  fontSize: 'inherit',
                  color: 'primary.main',
                  ...theme.applyStyles('dark', {
                    color: 'primary.light',
                  }),
                })}
              >
                2023 - 2024
              </Typography>
            </Typography>
          </Stack>
        </Container>
      </Box>

      <Container id="features" sx={{ py: { xs: 4, sm: 6 } }}>
      <Grid container spacing={6}>
      <Grid item xs={12} md={6} sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}>
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={(theme) => ({
                width: '100%',
                minHeight: '700px',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                overflow: 'hidden',
                backgroundImage: 'var(--items-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items-imageDark)',
                }),
              })}
              style={{
                '--items-imageLight': items[selectedItemIndex].imageLight,
                '--items-imageDark': items[selectedItemIndex].imageDark,
              }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <div>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <Typography variant="h2" sx={{ color: 'text.primary', marginRight: 1 }}>
              Guerrero,
            </Typography>
            <Typography variant="h2" sx={{ color: 'primary.light' }}>
              2023
            </Typography>
          </Box>
          </div>
          <div>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <Typography variant="h3" sx={{ color: 'orange', marginRight: 1 }}>
              Suspendido a Causa del Huracán Otis en el 2023
            </Typography>
          </Box>
          </div>
          <Card variant="outlined" sx={{ display: { xs: 'auto', sm: 'none' }, mt: 4 }}>
            <Box
              sx={(theme) => ({
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: 280,
                backgroundImage: 'var(--items-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items-imageDark)',
                }),
              })}
              style={{
                '--items-imageLight': items[selectedItemIndex].imageLight,
                '--items-imageDark': items[selectedItemIndex].imageDark,
              }}
            />
            <Box sx={{ px: 2, pb: 2, pt: 2 }}>
              <Typography gutterBottom variant="h6" sx={{ color: 'text.primary', fontWeight: 'medium' }}
                    dangerouslySetInnerHTML={{ __html: selectedFeature.title.replace(/\n/g, '<br>') }}>
              </Typography>
              <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5 }}
                    dangerouslySetInnerHTML={{ __html: selectedFeature.description.replace(/\n/g, '<br>') }}>
              </Typography>
            </Box>
          </Card>
          <Stack
            direction="column"
            spacing={2}
            useFlexGap
            sx={{
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
            }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                component={Button}
                sx={[
                  (theme) => ({
                    p: 3,
                    height: 'fit-content',
                    width: '100%',
                    background: 'none',
                    '&:hover': {
                      background: 'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)',
                      borderColor: 'primary.light',
                      boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)',
                      ...theme.applyStyles('dark', {
                        background: 'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
                        borderColor: 'primary.dark',
                        boxShadow: '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                      }),
                    },
                  }),
                  selectedItemIndex === index &&
                    ((theme) => ({
                      backgroundColor: 'action.selected',
                      borderColor: 'primary.light',
                      ...theme.applyStyles('dark', {
                        borderColor: 'primary.dark',
                      }),
                    })),
                ]}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={[
                      (theme) => ({
                        color: 'grey.400',
                        ...theme.applyStyles('dark', {
                          color: 'grey.600',
                        }),
                      }),
                      selectedItemIndex === index && {
                        color: 'primary.main',
                      },
                    ]}
                  >
                    {icon}
                  </Box>
                  <div>
                    <Typography gutterBottom variant="h6" sx={{ color: 'text.primary', fontWeight: 'medium', whiteSpace: 'pre-line'  }}
                    dangerouslySetInnerHTML={{ __html: title.replace(/\n/g, '<br>') }}>
                    </Typography>
                    <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5, whiteSpace: 'pre-line'  }}
                    dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br>') }}>
                    </Typography>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Container>

    <Container id="features" sx={{ py: { xs: 4, sm: 6 } }}>
      <Grid container spacing={6}>
      <Grid item xs={12} md={6} sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}>
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={(theme) => ({
                width: '100%',
                minHeight: '700px',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                overflow: 'hidden',
                backgroundImage: 'var(--items-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items-imageDark)',
                }),
              })}
              style={{
                '--items-imageLight': items3[selectedItemIndex].imageLight,
                '--items-imageDark': items3[selectedItemIndex].imageDark,
              }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <div>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <Typography variant="h2" sx={{ color: 'text.primary', marginRight: 1 }}>
              Chihuahua,
            </Typography>
            <Typography variant="h2" sx={{ color: 'primary.light' }}>
              2024
            </Typography>
          </Box>
          </div>
          <Card variant="outlined" sx={{ display: { xs: 'auto', sm: 'none' }, mt: 4 }}>
            <Box
              sx={(theme) => ({
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: 280,
                backgroundImage: 'var(--items-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items-imageDark)',
                }),
              })}
              style={{
                '--items-imageLight': items3[selectedItemIndex].imageLight,
                '--items-imageDark': items3[selectedItemIndex].imageDark,
              }}
            />
            <Box sx={{ px: 2, pb: 2, pt: 2 }}>
              <Typography gutterBottom variant="h6" sx={{ color: 'text.primary', fontWeight: 'medium' }}
                    dangerouslySetInnerHTML={{ __html: selectedFeature.title.replace(/\n/g, '<br>') }}>
              </Typography>
              <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5 }}
                    dangerouslySetInnerHTML={{ __html: selectedFeature.description.replace(/\n/g, '<br>') }}>
              </Typography>
            </Box>
          </Card>
          <Stack
            direction="column"
            spacing={2}
            useFlexGap
            sx={{
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
            }}
          >
            {items3.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                component={Button}
                sx={[
                  (theme) => ({
                    p: 3,
                    height: 'fit-content',
                    width: '100%',
                    background: 'none',
                    '&:hover': {
                      background: 'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)',
                      borderColor: 'primary.light',
                      boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)',
                      ...theme.applyStyles('dark', {
                        background: 'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
                        borderColor: 'primary.dark',
                        boxShadow: '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                      }),
                    },
                  }),
                  selectedItemIndex === index &&
                    ((theme) => ({
                      backgroundColor: 'action.selected',
                      borderColor: 'primary.light',
                      ...theme.applyStyles('dark', {
                        borderColor: 'primary.dark',
                      }),
                    })),
                ]}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={[
                      (theme) => ({
                        color: 'grey.400',
                        ...theme.applyStyles('dark', {
                          color: 'grey.600',
                        }),
                      }),
                      selectedItemIndex === index && {
                        color: 'primary.main',
                      },
                    ]}
                  >
                    {icon}
                  </Box>
                  <div>
                    <Typography gutterBottom variant="h6" sx={{ color: 'text.primary', fontWeight: 'medium', whiteSpace: 'pre-line'  }}
                    dangerouslySetInnerHTML={{ __html: title.replace(/\n/g, '<br>') }}>
                    </Typography>
                    <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5, whiteSpace: 'pre-line'  }}
                    dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br>') }}>
                    </Typography>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Container>

    {/*<Stack
      spacing={2}
      useFlexGap
      sx={{ alignItems: 'center', width: { xs: '100%', sm: '100%' } }}>
      <Typography
        variant="h1"
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          fontSize: 'clamp(3rem, 10vw, 3.5rem)',
          color: 'Darkred',
          marginTop: '50px',
        }}
      >
        Edición suspendida a causa del Huracán Otis
      </Typography>
    </Stack> 
    <StyledBox id="image" />*/}

    <Container id="features" sx={{ py: { xs: 4, sm: 8 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={4}>
          <div>
            <Typography variant="h3" sx={{ color: 'text.primary', mb: { xs: 2, sm: 2 } }}>
            Actividad y desarrollo en
            </Typography>
            <Typography variant="h3" sx={{ color: 'primary.light', mb: { xs: 2, sm: 2 } }}>
            México de cohetería experimental
            </Typography>
          </div>
          <Card variant="outlined" sx={{ display: { xs: 'auto', sm: 'none' }, mt: 4 }}>
            <Box
              sx={(theme) => ({
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: 280,
                backgroundImage: 'var(--items2-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items2-imageDark)',
                }),
              })}
              style={{
                '--items2-imageLight': items2[selectedItemIndex].imageLight,
                '--items2-imageDark': items2[selectedItemIndex].imageDark,
              }}
            />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography gutterBottom variant="h6" sx={{ color: 'text.primary', fontWeight: 'medium', whiteSpace: 'pre-line' }}
                    dangerouslySetInnerHTML={{ __html: selectedFeature2.title.replace(/\n/g, '<br>') }}>
              </Typography>
              <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5, whiteSpace: 'pre-line'  }}
                    dangerouslySetInnerHTML={{ __html: selectedFeature2.description.replace(/\n/g, '<br>') }}>
              </Typography>
            </Box>
          </Card>
          <Stack
            direction="column"
            spacing={2}
            useFlexGap
            sx={{
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
            }}
          >
            {items2.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                component={Button}
                sx={[
                  (theme) => ({
                    p: 3,
                    height: 'fit-content',
                    width: '100%',
                    background: 'none',
                    '&:hover': {
                      background: 'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)',
                      borderColor: 'primary.light',
                      boxShadow: '0px 2px 8px hsla(0, 0%, 0%, 0.1)',
                      ...theme.applyStyles('dark', {
                        background: 'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
                        borderColor: 'primary.dark',
                        boxShadow: '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                      }),
                    },
                  }),
                  selectedItemIndex === index &&
                    ((theme) => ({
                      backgroundColor: 'action.selected',
                      borderColor: 'primary.light',
                      ...theme.applyStyles('dark', {
                        borderColor: 'primary.dark',
                      }),
                    })),
                ]}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={[
                      (theme) => ({
                        color: 'grey.400',
                        ...theme.applyStyles('dark', {
                          color: 'grey.600',
                        }),
                      }),
                      selectedItemIndex === index && {
                        color: 'primary.main',
                      },
                    ]}
                  >
                    {icon}
                  </Box>
                  <div>
                    <Typography gutterBottom variant="h6" sx={{ color: 'text.primary', fontWeight: 'medium', whiteSpace: 'pre-line' }}
                    dangerouslySetInnerHTML={{ __html: title.replace(/\n/g, '<br>') }}>
                    </Typography>
                    <Typography variant="h6" sx={{ color: 'text.primary', mb: 1.5, whiteSpace: 'pre-line'  }}
                    dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br>') }}>
                    </Typography>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={8} sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}>
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={(theme) => ({
                width: '100%',
                height: '100%',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                overflow: 'hidden',
                backgroundImage: 'var(--items2-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items2-imageDark)',
                }),
              })}
              style={{
                '--items2-imageLight': items2[selectedItemIndex].imageLight,
                '--items2-imageDark': items2[selectedItemIndex].imageDark,
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>

      <Box
        id="hero"
        sx={(theme) => ({
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
          ...theme.applyStyles('dark', {
            backgroundImage:
              'radial-gradient(ellipse 80% 40% at 50% 60%, hsl(210, 100%, 16%), transparent)',
          }),
        })}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: { xs: 14, sm: 20 },
            pb: { xs: 10, sm: 10 },
          }}
        >
          <Stack
            spacing={2}
            useFlexGap
            sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
          >
            <Typography
              variant="h1"
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                fontSize: 'clamp(3rem, 10vw, 3.5rem)',
              }}
            >
              Galería&nbsp;
              <Typography
                component="span"
                variant="h1"
                sx={(theme) => ({
                  fontSize: 'inherit',
                  color: 'primary.main',
                  ...theme.applyStyles('dark', {
                    color: 'primary.light',
                  }),
                })}
              >
                2023 - 2024
              </Typography>
            </Typography>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
}