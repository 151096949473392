import * as React from 'react';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
//import InputLabel from '@mui/material/InputLabel';
//import TextField from '@mui/material/TextField';

//import { visuallyHidden } from '@mui/utils';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

export default function ComingSoonStore() {
  return (
    <Box
      id="contactUs"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
            }}
          >
            ¡Nuestra tienda,&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: 'inherit',
                color: 'primary.main',
                ...theme.applyStyles('dark', {
                  color: 'primary.light',
                }),
              })}
            >
              muy pronto!
            </Typography>
          </Typography>
          <Typography component="h2" variant="h5" sx={{ color: 'text.secondary', mt: 2  }}>
            ¡Escríbenos un mensaje!
          </Typography>
          <Typography component="h2" variant="h4" sx={{ color: 'text.primary', mt: 1  }}>
            contacto@enmice.mx
          </Typography>
          <Typography component="h2" variant="h5" sx={{ color: 'text.secondary', mt: 2  }}>
            ¡O búscanos en nuestras redes sociales!
          </Typography>
          <Stack
          direction="row"
          spacing={6}
          useFlexGap
          sx={{ justifyContent: 'left', color: 'text.secondary', mt: 1 }}
        >
          <IconButton
            color="inherit"
            href="https://www.facebook.com/enmicemx"
            aria-label="Facebook"
            sx={{ alignSelf: 'center' }}
          >
            <FacebookIcon sx={{ fontSize: 60 }}/>
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.instagram.com/enmicemx"
            aria-label="Instagram"
            sx={{ alignSelf: 'center' }}
          >
            <InstagramIcon sx={{ fontSize: 60 }}/>
          </IconButton>
          <IconButton
            color="inherit"
            href="https://mx.linkedin.com/company/enmicemx"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}
          >
            <LinkedInIcon sx={{ fontSize: 60 }}/>
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.youtube.com/channel/UCjmAheB1faG9Z0qZmkyejKQ&ved=2ahUKEwiD9eeakayHAxUWJEQIHc0SCfoQFnoECBEQAQ"
            aria-label="YouTube"
            sx={{ alignSelf: 'center' }}
          >
            <YouTubeIcon sx={{ fontSize: 60 }}/>
          </IconButton>
        </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
