import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import imageIndex from '../ImageIndex';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
//import { styled } from '@mui/material/styles';

/*const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 400,
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: '1px solid',
  boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
  backgroundImage: `url(${'https://enmicemedia.blob.core.windows.net/media/Página web ENMICE 2024-2025/Audiovisual para contenido/Academia ENMICE/IMG_9231.JPG'})`,
  outlineColor: 'hsla(220, 25%, 80%, 0.5)',
  backgroundSize: 'cover',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
    backgroundImage: `url(${'/static/images/templates/templates-images/hero-dark.png'})`,
    outlineColor: 'hsla(210, 100%, 80%, 0.1)',
  }),
}));*/

const ImageCarousel = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const carouselImageStyle = {
    width: '100%', // Adjust width as needed
    height: '500px', // Adjust height as needed
    objectFit: 'cover', // This ensures the images cover the div without stretching
    position: 'relative',
  };

  const baseOverlayTextStyle = {
    position: 'absolute',
    top: '70%',
    backgroundColor: 'hsla(220, 25%, 10%, 0.9)',
    borderRadius: '12px',
    padding: '20px',
    fontSize: '40px',
    margin: 10
  };

  const mobileOverlayTextStyle = {
    fontSize: '10px', // Smaller font size for mobile
    padding: '20px', // Less padding for mobile
    top: '50%' // Adjust position for mobile
  };

  const overlayTextStyle = isMobile ? { ...baseOverlayTextStyle, ...mobileOverlayTextStyle } : baseOverlayTextStyle;

  const sliderTextStyle1 = {
    flexDirection: 'row',
    margin: 0,
    fontWeight: 600,
    lineHeight: 1.2,
    letterSpacing: '-0.5px',
    fontFamily: 'Inter, sans-serif',
    display: 'flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    fontSize: 'clamp(3rem, 10vw, 3.5rem)',
    color: 'white'
  };

  const sliderTextStyle2 = {
    margin: 0,
    fontWeight: 600,
    lineHeight: 1.2,
    letterSpacing: '-0.5px',
    fontFamily: 'Inter, sans-serif',
    color: 'hsl(210, 100%, 65%)',
    fontSize: 'inherit',
  };

  return (
    <Slider {...settings}>
      <div style={carouselImageStyle}>
        <img src={imageIndex.AcademiaCarousel2} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
        {!isMobile && (
        <div style={overlayTextStyle}>
        <h1 style={sliderTextStyle1}>Plataforma líder</h1>
        <h1 style={sliderTextStyle2}>de aprendizaje del sector espacial</h1>
        </div>
        )}
      </div>
      <div style={carouselImageStyle}>
        <img src={imageIndex.AcademiaCarousel3} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
        {!isMobile && (
        <div style={overlayTextStyle}>
          <h1 style={sliderTextStyle1}>Plataforma líder</h1>
          <h1 style={sliderTextStyle2}>de aprendizaje del sector espacial</h1>
        </div>
        )}
      </div>
      <div style={carouselImageStyle}>
        <img src={imageIndex.AcademiaCarousel4} alt="Evento" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
        {!isMobile && (
        <div style={overlayTextStyle}>
        <h1 style={sliderTextStyle1}>Plataforma líder</h1>
        <h1 style={sliderTextStyle2}>de aprendizaje del sector espacial</h1>
        </div>
        )}
      </div>
    </Slider>
  );
};

export default function AcademiaHero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <ImageCarousel />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 15, sm: 15 },
          pb: { xs: 5, sm: 5 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <Box
            component="img"
            src={imageIndex.AcademiaENMICELogo}
            alt="Academia ENMICE"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              width: '50%', // Adjust as needed
              height: 'auto', // Adjust as needed
              maxWidth: '100%', // Ensure the image is responsive
            }}
          />
        </Stack>
        <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 15, sm: 15 },
          pb: { xs: 5, sm: 5 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
            }}
          >
            ¿Qué es&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: 'inherit',
                color: 'primary.main',
                ...theme.applyStyles('dark', {
                  color: 'primary.light',
                }),
              })}
            >
              Academia ENMICE?
            </Typography>
          </Typography>
          <Typography
            variant="h5"
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '80%' },
            }}
          >
            Academia ENMICE es la plataforma líder en México de formación y aprendizaje extracurricular en el sector aeroespacial y espacial. Hoy tenemos más de 300 registros y ofrecemos más de 15 actividades diferentes dirigidos a públicos especializados y no especializados.
          </Typography>
          <Typography
            variant="h5"
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '80%' },
            }}
          >
           A través de nuestros cursos, talleres, capacitaciones y actividades fomentamos el interés, desarrollo de habilidades y conocimientos enfocados en las ciencias y tecnologías aeroespaciales y espaciales.
          </Typography>
          <Typography
            variant="h5"
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '80%' },
            }}
          >
            En Academia ENMICE proponemos una nueva forma de aprendizaje para todas las edades, grupos personalizados, instituciones, entidades públicas y privadas con el objetivo de seguir impulsando la comunidad y el sector aeroespacial en México y Latinoamérica.
          </Typography>
        </Stack>       
      </Container>
        {/*<StyledBox id="image" />*/}
      </Container>
    </Box>
  );
}
